import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/system';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import theme from '../theme';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

const ArtworkCard = styled(Card)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	// justifyContent: 'center',
	position: 'relative',
	color: theme.palette.secondary.main,
	zIndex: 0,
	':after': {
		position: 'absolute',
		display: 'block',
		width: 'calc(100% - 20px)',
		height: 'calc(100% - 20px)',
		content: '" "',
		top: '10px',
		left: '10px',
		// border: '1px solid rgba(0, 0, 0, 0.15)',
		border: '4px solid rgba(228, 216, 171, 0.45)',
		zIndex: -1,
	},
	'.MuiCardHeader-root': {
		transition: 'all 2s ease',
		// position: 'absolute',
	},
	'.MuiCardContent-root': {
		padding: theme.spacing(5),
		paddingTop: theme.spacing(2),
	},
	'.filter': {
		position: 'absolute',
		backgroundColor: 'rgba(0,0,0, 0.5)',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '-1',
		transition: 'all 0.5s ease',
	},
	'.contentShow': {
		position: 'absolute',
		bottom: '-9999px',
	},
	'&:hover': {
		transition: 'all 0.5s ease',
		// color: theme.palette.primary.main,
		'& .filter': {
			backgroundColor: 'rgba(255, 255, 255, 0.7)',
			transition: 'all 0.5s ease',
			transform: 'scale(1.2)',
		},
		'.MuiCardHeader-root': {
			// zIndex: 2,
			transition: 'all 2s ease',
			// position: 'relative',
		},
	},
}));

class GalleryListTemplate extends React.Component {
	render() {
		const { data } = this.props;
		const { edges: artworks } = data.allGalleryJson;

		return (
			<Section
				px={1}
				py={1.5}
				sx={{
					// px: { sx: 2, md: 6 },
					backgroundColor: 'transparent',
				}}
				id='gallery'
			>
				<Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={1} sx={{ maxWidth: '1536px' }}>
					{artworks &&
						artworks.map(({ node: artwork }) => (
							<ArtworkCard className='card card-with-border' key={`${artwork.id}`}>
								{artwork.image ? (
									<CardHeader
										sx={{
											p: 0,
											width: '100%',
											'.MuiCardHeader-avatar': { width: '100%' },
											'.gatsby-image-wrapper': {
												width: '100%',
												borderRadius: 0,
												zIndex: -1,
											},
										}}
										avatar={
											<PreviewCompatibleImage
												imageInfo={{
													image: artwork.image,
													alt: `featured image thumbnail for post ${artwork.name}`,
												}}
											/>
										}
									/>
								) : null}
								<CardContent className='content text-center'>
									<Typography
										textAlign='center'
										variant='h3'
										sx={{
											fontSize: '2.3rem',
											color: theme.palette.primary.main,
											textShadow:
												'-1px -1px 1px rgba(255, 255, 255, 0.2), 1px 1px 1px rgba(0, 0, 0, 0.6)',
										}}
									>
										{artwork.name}
									</Typography>
									<Typography textAlign='center' variant='body1' className='description'>
										{artwork.description}
									</Typography>
								</CardContent>
								{artwork.excerpt ? (
									<CardActions
										className='footer text-center'
										sx={{
											display: 'flex',
											p: 2,
											pb: 4,
											pt: 0,
											justifyContent: 'center',
										}}
									>
										<Button
											variant='aleign'
											component={Link}
											to={artwork.slug}
											sx={{
												'&:hover,&:focus,&:active,&.active': {
													color: '#fff',
													backgroundColor: theme.palette.primary.main,
													borderColor: theme.palette.primary.main,
													'&:after, &:before': { background: theme.palette.primary.main },
												},
											}}
										>
											Continue Reading
										</Button>
									</CardActions>
								) : null}
								{/* <Box className='filter'></Box> */}
							</ArtworkCard>
						))}
				</Masonry>
			</Section>
		);
	}
}

GalleryList.propTypes = {
	data: PropTypes.shape({
		allC3GalleryJson: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

export default function GalleryList() {
	return (
		<StaticQuery
			query={graphql`
				query GalleryListQuery {
					allGalleryJson {
						edges {
							node {
								artist
								material
								completed
								filters
								price
								size
								name
								description
								id
								image {
									childImageSharp {
										gatsbyImageData(quality: 100, layout: FULL_WIDTH)
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <GalleryListTemplate data={data} count={count} />}
		/>
	);
}
