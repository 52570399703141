// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { StaticImage } from 'gatsby-plugin-image';
import { alpha } from '@mui/material';
import theme from '../theme';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import HeroSectionTheir from '../components/sections/HeroSectionTheir';
import Mantra from '../components/sections/ALEiGN-mantraV2';
import NOOBIntro from '../components/sections/NOOB-Intro';
import ALEiGNToolbox from '../components/sections/ALEiGN-Toolbox';
import AboutSection from '../components/AboutSectionV2';
import GalleryList from '../components/GalleryList';
import instructions from '../img/ALEiGN-Instructions-OPT.gif';
import signPost from '../img/ALEiGNd-signpost.jpg';
import paintBkgrd from '../img/paintBkGrd1.jpg';
import logo from '../img/CBailey-wht.svg';

// import ProductList from '../components/ProductList';

// import Productive from '../img/being-productive.mp4';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0.3),
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
});

const SectionContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100vh',
	overflow: 'hidden',
	justifyContent: 'center',
});

const StatBox = styled(Box)(({ theme }) => ({
	border: '6px solid',
	padding: 20,
	width: 'fit-content',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	position: 'relative',
	'.filter': {
		position: 'absolute',
		backgroundColor: alpha(theme.palette.background.default, 0.6),
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '-1',
		transition: 'all 0.5s ease',
	},
}));

const SuccessCard = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	color: '#333',
	padding: theme.spacing(2),
	marginBottom: theme.spacing(3),
	'MuiTypography-h3': {
		lineHeight: 1,
	},
	'.MuiTypography-h6': {
		fontWeight: 600,
		fontSize: '1rem',
		lineHeight: 1,
		color: theme.palette.primary.main,
		paddingTop: theme.spacing(1),
	},
}));

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	padding: theme.spacing(12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
}));

const ScrollAnchor = styled(Box)({
	position: 'absolute',
});

// const Purple = purple[200];

// eslint-disable-next-line
export const ALEIGNintroducingTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	main,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			{/* <HeroSectionTheir /> */}
			<SectionContent py={8} px={6} m={0}>
				<Box
					sx={{
						maxHeight: '100vh',
						position: 'absolute',
						width: '100%',
						height: '100vh',
						top: '0px',
						left: '0px',
						// opacity: '0.2',
						img: {
							// bottom: 0,
							height: '100%',
							// left: 0,
							margin: 0,
							maxWidth: 'none',
							padding: 0,
							position: 'absolute',
							// right: 0,
							// top: '20px',
							width: '100%',
							objectFit: 'cover',
						},
					}}
				>
					<img src={paintBkgrd} alt='painted background' />
				</Box>
				<Box my={0} display='flex' sx={{ mb: 0, justifyContent: 'center' }}>
					<StaticImage
						src='../img/CBailey-wht.svg'
						alt='Christopher Bailey signature logo'
						placeholder='blurred'
					/>
				</Box>
				<Grid container justify='space-between' sx={{ zIndex: '1' }}>
					{/* <Grid item xs={0} md={7}></Grid> */}
					<Grid item xs={12} sm={12} sx={{ textAlign: 'center' }}>
						<Typography
							variant='h3'
							mb={2}
							sx={{ fontWeight: 700, textTransform: 'none' }}
							color='#fff'
						>
							Artist .. Sculptor .. Master Jeweller ..
						</Typography>
						{/* <Typography variant='h3' color='primary' sx={{ fontWeight: 'inherit' }}>
							If all else fails,
						</Typography>
						<Typography variant='h2' color='primary' sx={{ fontWeight: 'inherit' }}>
							Read the instructions!
						</Typography> */}
					</Grid>
				</Grid>
				<Stack
					direction='row'
					alignItems='center'
					spacing={6}
					mt={4}
					sx={{ justifyContent: { xs: 'center' } }}
				>
					<Button href='#gallery' variant='contained'>
						View the Gallery
					</Button>
					<Button
						color='secondary'
						size='lg'
						variant='contained'
						href='javascript:void(Tawk_API.toggle())'
					>
						Leave a message
					</Button>
				</Stack>
			</SectionContent>
			{/* <ProductList /> */}
			{/* <AboutSection /> */}
			{/* <Mantra /> */}
			{/* <ALEiGNToolbox /> */}
			<GalleryList />
		</>
	);
};

ALEIGNintroducingTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ALEIGNintroducing = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<ALEIGNintroducingTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				main={frontmatter.main}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ALEIGNintroducing.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ALEIGNintroducing;

export const pageQuery = graphql`
	query introducingTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "aleign-theirPage" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(quality: 100, layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}
	}
`;
